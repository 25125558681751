import logo from './logo.svg';
import Home from './screens/home';
import About from './screens/about';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' exact element={<About />} />
      </Routes>
  </Router>
    </>
  )
}

export default App;
