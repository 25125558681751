import React, { useEffect,useState } from 'react';
import './home.css';
import Footer from './footer';
import  my1 from './my1.jpg';
import  my2 from './my2.jpg';
import  my3 from './my3.jpg';
import  my4 from './my4.jpg';
import  my5 from './my5.jpg';
function Home(props) {

    const [header, setHeader] = useState("header")
    const [color, setColor] = useState('')

const listenScrollEvent = (event) => {
    if (window.scrollY >= window.innerHeight - 40) {
       // alert('hadippa')
        setColor('#80CBC440')
        return setHeader("header")
    } else {
        setColor('')

        return setHeader("header")
    }
//   if (window.scrollY < 73) {

//     return setHeader("header")
//   } else if (window.scrollY > 70) {
//     return setHeader("header2")
//   } 
}

useEffect(() => {
  window.addEventListener('scroll', listenScrollEvent);

  return () =>
    window.removeEventListener('scroll', listenScrollEvent);
}, []);
     
    return (
        <>
        <div>
            <div className="first-container">
                <div className="nav-container" style={{backgroundColor:color}}>
                    <div className="nav-row">
                        <div className="nav-logo">
                            <a >
                                Aman Mehra
                            </a>
                        </div>
                        <div className="nav-controls">
                            <a >Home</a>
                            <a href="/about">About</a>
                            <a >Albums</a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row">
			{/* <h2>
				Aman Mehra
			</h2> */}
			<p className='para'>
				The Company – Soulful Melodies is a Columbus, Ohio USA based music company incorporated in 2023 where
				very tasteful, superior and melodious Hindi devotional songs (Bhajans), poetic songs of love from
				ancient Indian and middle eastern literature (Ghazals) and modern but meaningful, deep and heartfelt
				(Indian songs) are created.
				The numbers are primarily In Hindi and Urdu languages, sung and composed by our founder Aman Mehra.
				Lyrics are written in Hindi and Urdu languages by world class famous writers and poets. Melodies of
				other famous musicians and composers are also used to create top notch soul touching music.

				Our Founder – Our founder Aman Mehra is an Indian American who was born in India but has been living in
				USA for more than 30 years. He is associated with Kirana Gharana and his style of singing is focused on
				“ Aakar gaiki” “daanedar gaiki” and “Bhav poorn gaiki”. He sings light music with a classical effect.
				All his music is based on famous Hindustani classical ragas. From his early childhood he showed unusual
				interest, understanding and passion for Hindustani Classical and Bollywood music. He use to listen and
				hum the bhajan and ghazals of greats such as Bharat Ratna Bhimsen Joshi, Padamshree Pushottam Das
				Jalota, Padma vibhushan Jagjit Singh, Padamshree Hariharan, Bhajan Samrat Padamshree Anup Jalota and pt.
				Naveen Kumar.

			</p>
		</div>
	</div>
	<section className="card-container">
		<div className="flip-card">
			<div className="content">
				<div className="front" style={{backgroundColor:'transparent'}}>
                <img style={{height:'100%',backgroundColor:'transparent'}} src={my1} alt="Logo" />
				</div>
				<div className="back"  style={{backgroundColor:'transparent'}}>
                <img style={{height:'100%'}} src={my4} alt="Logo" />
				</div>
			</div>
		</div>

		<div className="flip-card">
			<div className="content">
				<div className="front" style={{backgroundColor:'transparent'}}>
                <img style={{height:'100%'}} src={my2} alt="Logo" />
				</div>
				<div className="back" style={{backgroundColor:'transparent'}}>
                <img style={{height:'100%'}} src={my5} alt="Logo" />
				</div>
			</div>
		</div>

		<div className="flip-card">
			<div className="content">
				<div className="front" style={{backgroundColor:'transparent'}}>
                <img style={{height:'100%'}} src={my3} alt="Logo" />

				</div>
				<div className="back" style={{backgroundColor:'transparent'}}>
                <img style={{height:'100%'}} src={my1} alt="Logo" />

				</div>
			</div>
		</div>
	</section>

    <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',margin: '5%'}}>
    <iframe
      width="853"
      height="480"
      src="https://www.youtube.com/embed/VjZSCQFTHCI"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
	</div>
    <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',margin: '5%'}}>
    <iframe
      width="853"
      height="480"
      src="https://www.youtube.com/embed/3RxP_vsezlA"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
	</div>
	      <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',margin: '5%'}}>
    <iframe
      width="853"
      height="480"
      src="https://www.youtube.com/embed/8CvLOJMKT9I"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
	</div>
        </div>
    {/* <Footer/> */}
</>
    );
}

export default Home;
