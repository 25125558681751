import React from 'react';
import './about.css'
function About(props) {
    return (
        <div>
             <div className="header-container">
        <div className="nav-container">
            <div className="nav-row">
                <div className="nav-logo">
                    <a href="#">
                        Aman Mehra
                    </a>
                </div>
                <div className="nav-controls">
                    <a href="/">Home</a>
                    <a href="/about">About</a>
                    <a href="#">Albums</a>
                </div>
            </div>
        </div>
    </div>
    <div className="about-container">
        <div className="row">
            <div className="info">
                <p>
                    The Company – Soulful Melodies is a Columbus, Ohio USA based music company incorporated in 2023 where very
                    tasteful, superior and melodious Hindi devotional songs (Bhajans), poetic songs of love from ancient Indian
                    and middle eastern literature (Ghazals) and modern but meaningful, deep and heartfelt (Indian songs) are
                    created.
                    The numbers are primarily In Hindi and Urdu languages, sung and composed by our founder Aman Mehra. Lyrics
                    are written in Hindi and Urdu languages by world class famous writers and poets. Melodies of other famous
                    musicians and composers are also used to create top notch soul touching music.
                </p>
        
                <p>
                    Our Founder – Our founder Aman Mehra is an Indian American who was born in India but has been living in USA
                    for more than 30 years. He is associated with Kirana Gharana and his style of singing is focused on “ Aakar
                    gaiki” “daanedar gaiki” and “Bhav poorn gaiki”. He sings light music with a classical effect. All his music
                    is based on famous Hindustani classical ragas. From his early childhood he showed unusual interest,
                    understanding and passion for Hindustani Classical and Bollywood music. He use to listen and hum the bhajan
                    and ghazals of greats such as Bharat Ratna Bhimsen Joshi, Padamshree Pushottam Das Jalota, Padma vibhushan
                    Jagjit Singh, Padamshree Hariharan, Bhajan Samrat Padamshree Anup Jalota and pt. Naveen Kumar.
        
                </p>
                <p>
                    Mr. Mehra’ s quest and learning of Hindustani classical music began at an early stage and the journey still
                    continues. He believes that one life is not enough to learn and master the rich Hindustani classical music.
                    He is very disciplined and learns and practices Hindi classical music every day.
                </p>
                <p>
                    Even though Mr. Mehra has lived in USA most of his life his command over Hindi and Urdu languages and his
                    understanding of the nuances of Indian music is unparalleled. He deeply believes in the rich Indian Culture,
                    values and traditions and performs ghazals, bhajans and songs in Various Indian American events all
                    throughout United States.
                </p>
                <p>
                    Our current projects: Currently we have six projects on the production floor to be released on famous
                    platforms such as Utube, Instagram, Facebook and all the other more than 150 social media platforms and
                    mediums by mid April 2023.
        
                </p>
                <p>
                    These include two bhajans, two ghazals and two songs. These are sung and composed by Mr. Mehra. Music
                    direction is by Pt. Naveen Kumar. Lyrics are by famous writers and poets. There are also some famous
                    musicians who are involved in these projects. Credit attributes of participating artists will be provided
                    after the release.
                    Our Future projects: We also have six projects which are in their infancy. They will be released by April
                    2024. Again these will be bhajans, ghazals and songs. We also believe in experimentation and creating new
                    flavors. We will be introducing some modern but tasteful fusion songs in the next round of release, again
                    based on famous ragas.
        
                </p>
            </div>
        </div>
    </div>

   
    
    <br></br>
        </div>
    );
}

export default About;